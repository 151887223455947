import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse  
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastService } from '../toaster.service';
import { ErrorCode } from 'src/app/core/app-constants';
import { AuthService } from './auth.services';
import { NavController } from '@ionic/angular';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  /**
   *
   * @param req
   * @param next
   * expected output from api : {message:"", data:[] | {} }
   */
  constructor(private toasterService: ToastService,
    private authService: AuthService,
    private navCtrl: NavController) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('called response interceptor', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (Math.floor(err.status / 100) === 4) {
            const responseError = err.error;
            if (err.status === 403) {
              //  Handling the GSTN API response
            } else if (responseError['title'] === 'Session expired') {
              
              this.authService.initializeAppData();
              this.navCtrl.navigateRoot(['landing']);
            }
          }
          if (Math.floor(err.status / 100) === 5) {
            console.log('err.error =', err.error, ';');
            const responseError = err.error;
            if (responseError['data']){
              console.log('called');
            } else if (ErrorCode.TOKEN_INVALID === responseError['code']
              || ErrorCode.TOKEN_EXPIRED === responseError['code']) {
              this.authService.initializeAppData();
              this.navCtrl.navigateRoot(['landing']);
            }
          }
          console.log(err);
          return throwError(err);
        } else {
          return of(err);
        }
      })
    );
  }
}
