import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage.service';
import { ApiRequestService } from '../api_request.service';
import { AppData } from '../app_data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private storageService: StorageService,
    private apiRequestService: ApiRequestService,
    private router: Router) {
  }

  isAuthenticated() {
    return AppData.isAuthenticated;
  }

  initializeAppData() {
    // AppData.cartIdSub$.next(null);
    // this.storageService.clearStorage();
  }
}
