import { Injectable } from '@angular/core';
import { AppData } from './app_data.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    setItem(key: string, item: any) {
        localStorage.setItem(key, JSON.stringify(item));
    }
    getItem(key: string) {
        try {
            console.log('Key:', key);
            console.log(localStorage.getItem(key));
            return JSON.parse(localStorage.getItem(key)!) //@typescript-eslint/no-non-null-assertion;
        } catch (error) {
            console.log("Error: ", error);
            return null;
        }
    }
    clearStorage() {
        localStorage.clear();
        AppData.cartIdSub$.next('');
        AppData.orderInfo$.next('');
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

}
