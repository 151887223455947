import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { URL_KEYS, RequestUrl } from "./request_url.service";
import { Observable } from 'rxjs';
import { ToastService } from './toaster.service';

@Injectable({
  providedIn: "root"
})

export class ApiRequestService {
  constructor(
    private httpClient: HttpClient,
    private toasterService: ToastService,
  ) { }

  get(url: URL_KEYS, query = "", headers?: any) {
    return new Observable(observer => {
      const reqUrlOptions = RequestUrl.get(url);
      // if (reqUrlOptions.showLoading) { this.loaderService.show(); }
      this.httpClient
        .get<Observable<any>>(reqUrlOptions.url + `${query}`, { observe: "response", headers })
        .subscribe(
          (response) => {
            observer.next(this.handleResponse(response, reqUrlOptions));
          },
          (error) => {
            observer.error(error);
            // this.loaderService.hide();
          }
        );
    });
  }

  post(url: URL_KEYS, body: any, headers?: any, query = "") {
    return new Observable(observer => {
      const reqUrlOptions = RequestUrl.get(url);
      // if (reqUrlOptions.showLoading) { this.loaderService.show(); }
      this.httpClient
        .post(reqUrlOptions.url + `${query}`, body, { observe: "response", headers })
        .subscribe(
          (response) => {
            observer.next(this.handleResponse(response, reqUrlOptions));
          },
          (error) => {
            observer.error(error);
            // this.loaderService.hide();
          }
        );
    });
  }

  put(url: URL_KEYS, query = "", body: any, headers?: any) {
    return new Observable(observer => {
      const reqUrlOptions = RequestUrl.get(url);
      // if (reqUrlOptions.showLoading) { this.loaderService.show(); }
      return this.httpClient
        .put(reqUrlOptions.url + `${query}`, body, { observe: "response", headers })
        .subscribe(
          (response) => {
            observer.next(this.handleResponse(response, reqUrlOptions));
          },
          (error) => {
            observer.error(error);
            // this.loaderService.hide();
          }
        );
    });
  }

  patch(url: URL_KEYS, query = "", body: any, headers?: any) {
    return new Observable(observer => {
      const reqUrlOptions = RequestUrl.get(url);
      // if (reqUrlOptions.showLoading) { this.loaderService.show(); }
      this.httpClient
        .patch(reqUrlOptions.url + `${query}`, body, {
          observe: "response",
          headers
        })
        .subscribe(
          (response) => {
            observer.next(this.handleResponse(response, reqUrlOptions));
          },
          (error) => {
            observer.error(error);
            // this.loaderService.hide();
          }
        );
    });
  }

  delete(url: URL_KEYS, query = "", body?: any, headers?: any) {
    return new Observable(observer => {
      const reqUrlOptions = RequestUrl.get(url);
      // if (reqUrlOptions.showLoading) { this.loaderService.show(); }
      const options = { headers: headers, body: body };
      this.httpClient
        .delete(reqUrlOptions.url + `${query}`, options)
        .subscribe(
          (response) => {
            observer.next(this.handleResponse(response, reqUrlOptions));
          },
          (error) => {
            observer.error(error);
            // this.loaderService.hide();
          }
        );
    });
  }

  handleResponse(response: any, reqUrlOptions: any) {
    if (Math.floor(response["status"] / 100) === 2 && reqUrlOptions.showMsg) {
      console.log("HttpResponse::event =", response, ";");
      // this.toasterService.presentToast(
      //     response["body"]["message"],
      //     "Success",
      //     "success"
      // );
    } else {
      console.log("event =", response, ";");
    }
    if (reqUrlOptions.showLoading) {
      // this.loaderService.hide();
    }
    return response['body'];
  }
}

