import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { map } from 'rxjs';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing',
    loadChildren: () => import('./module/landing-page/landing-page.module').then(m => m.LandingPageMModule),
  },
  {
    path: 'location',
    loadChildren: () => import('./module/location-page/location-page.module').then(m => m.LocationPageModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuard).CanMatchFn().pipe(map(is_assessable => is_assessable || router.createUrlTree(['/landing'])));
    }]
  },
  {
    path: 'address',
    loadChildren: () => import('./module/address-page/address-page.module').then(m => m.AddresstPageModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuard).CanMatchFn().pipe(map(is_assessable => is_assessable || router.createUrlTree(['/landing'])));
    }]
  },
  {
    path: 'cart',
    loadChildren: () => import('./module/checkout-page/checkout-page.module').then(m => m.CheckoutPageModule), canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuard).CanMatchFn().pipe(map(is_assessable => is_assessable || router.createUrlTree(['/landing'])));
    }]
  },
  {
    path: 'confirmation',
    loadChildren: () => import('../app/module/order-confirmation-modal/order-confirmation-modal.module').then(m => m.OrderConfirmationModalModule)

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
