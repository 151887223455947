<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ion-header class="ion-header ion-no-border">
  <div class="mx-3 py-3 d-flex align-items-center justify-content-between">
    <ion-text class="primary-black fw-8 fs-16 sitefontnormal text-capitalize">{{"Select " + from + ' Location' ||
      'Select Location'}}</ion-text>
    <img src="assets/svg-images/close-icon.svg" alt="" (click)="dismiss()">
  </div>
</ion-header>
<ion-content>
  <input class="fs-14 locationsearch" type="text" [(ngModel)]="searchStringM"
    placeholder="Search for area, street name..." (input)="initAutocomplete()" id="pac-input-1" #searchInput1
    style="outline: none;">
  <img src="assets/svg-images/cross_icon.svg" *ngIf="searchStringM.length !== 0" (click)="onClearInput()" alt=""
    class="position-absolute cross-icon p-1">
  <div #map id="map-view"></div>
  <div class="current-loc d-flex align-items-center justify-content-center w-100">
    <div class="d-flex align-items-center justify-content-center bg-white" (click)="getCurrentLocation()">
      <img src="assets/svg-images/location_icon.svg" class="me-2" alt="">
      <ion-text class="primary-clr fs-10 sitefontmedium fw-8">USE CURRENT LOCATION</ion-text>
    </div>
  </div>
  <div class="estimated-cost-sec bg-white position-fixed w-100 px-2" id="cost-sec">
    <div class="mb-3">
      <div class="d-flex align-items-start">
        <img src="assets/svg-images/location-green.svg" id="toolTip-position" class="pointer" alt=""
          style="filter: invert(68%) sepia(77%) saturate(1511%) hue-rotate(315deg) brightness(88%) contrast(91%);">
        <div>
          <ion-text id="building"
            class="fs-14 fw-8 d-block sitefontnormal primary-black me-2 limit-line-length-1"></ion-text>
          <ion-text *ngIf="!buildingName" class="sitefontmedium fs-14 fw-8 primary-black"></ion-text>
          <ion-text id="fullAddr" class="sitefontmedium fs-14 fw-5 limit-line-length-2" style="color: #65656B;"></ion-text>
        </div>
      </div>
    </div>
    <div class="px-2">
      <button class="btn primary-btn fs-14 fw-8" (click)="saveMapLoc()">Proceed</button>
    </div>
  </div>
</ion-content>