import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ApiRequestService } from "src/app/shared/api_request.service";
import { AppData } from "src/app/shared/app_data.service";
import { environment } from "src/environments/environment";

export interface ADDRESSINFO {
  fullAddress: string,
  houseNum: string,
  floor: string,
  googleLocation: string,
  landmark: string,
  city: string,
  state: string,
  country: string,
  pincode: string,
  contact?: any,
  longLat: { coordinates: any[] },
  countryCode: any,
  addressTag: any,
  name: any,
  saveType: boolean
}

@Injectable({
  providedIn: "root"
})

export class LocationService {
  onSetLocality: any; //method variable
  onSetLatLng: any;//method variable
  onSetFullAddress: any;//method variable
  onsetCityStateCountry: any; //method variable
  onSetGoogleLocation: any;
  locationInfo$ = new Subject();
  senderAddressDetailsInfo: ADDRESSINFO;
  addressLists: any[] = [];
  receiverAddressDetailsInfo: ADDRESSINFO;
  constructor(private apiRequestService: ApiRequestService) {
    this.senderAddressDetailsInfo = {
      fullAddress: '',
      houseNum: '',
      floor: '',
      googleLocation: '',
      landmark: '',
      city: '',
      contact: {},
      state: '',
      country: '',
      pincode: '',
      longLat: { coordinates: [] },
      countryCode: 'IN',
      addressTag: '',
      name: '',
      saveType: true
    }
    this.receiverAddressDetailsInfo = {
      fullAddress: '',
      houseNum: '',
      floor: '',
      googleLocation: '',
      landmark: '',
      city: '',
      contact: {},
      state: '',
      country: '',
      pincode: '',
      longLat: { coordinates: [] },
      countryCode: 'IN',
      addressTag: '',
      name: '',
      saveType: true
    }
    
  }


  init(setLocality: any, setLatLng: any, setFullAddress: any, setCityStateCountry: any, setGoogleLocation: any) {
    this.onSetLocality = setLocality;
    this.onSetLatLng = setLatLng;
    // this.onGetLocBaseOnLatLng = getLocBaseOnLatLng;
    this.onSetFullAddress = setFullAddress;
    this.onsetCityStateCountry = setCityStateCountry;
    this.onSetGoogleLocation = setGoogleLocation;
  }

  getCartId() {
    return this.apiRequestService.get('getCart');
  }
  currentLocation(lat: any, lng: any) {
    return this.apiRequestService.get('getCurrentAddress', '?latlng=' + lat + ',' + lng + '&key=' + environment.mapApiKey, '');
  }
  swapLocation() {
    return this.apiRequestService.post('swapLocation', { cartId: AppData?.cartId })
  }

  postSenderReceiverDetailsApi(payload: any) {
    return this.apiRequestService.post('senderReceiver', payload);
  }

  validateCart(cartId: string) {
    return this.apiRequestService.post('validateCart', { cartId });
  }

  staticContent() {
    return this.apiRequestService.get('static_content');
  }

  getSavedAddress() {
    return this.apiRequestService.get("address");
  }

  updatePackageDetails(payload: any) {
    return this.apiRequestService.post('packageType', payload);
  }

  resetSenderObj(){
    this.senderAddressDetailsInfo = {
      fullAddress: '',
      houseNum: '',
      floor: '',
      googleLocation: '',
      landmark: '',
      city: '',
      contact: {},
      state: '',
      country: '',
      pincode: '',
      longLat: { coordinates: [] },
      countryCode: 'IN',
      addressTag: '',
      name: '',
      saveType: true
    }
}
  resetReceiverObj(){
    this.receiverAddressDetailsInfo = {
      fullAddress: '',
      houseNum: '',
      floor: '',
      googleLocation: '',
      landmark: '',
      city: '',
      contact: {},
      state: '',
      country: '',
      pincode: '',
      longLat: { coordinates: [] },
      countryCode: 'IN',
      addressTag: '',
      name: '',
      saveType: true
    }
}

getLocBaseOnLatLng(lat: any, lng: any) {
  this.currentLocation(lat, lng).subscribe((res: any) => {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const responseData = res['results'];
    this.onSetFullAddress(responseData[0].formatted_address);
    this.getLocality(responseData[0].address_components);
    this.getCityStateCountry(responseData[0].address_components);
    this.onSetLatLng(responseData[0].geometry.location);
    console.log(res['results'])
  })
}
getCurrentPosition(): Promise < GeolocationPosition > {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
}

getLocality(responseData: any) {
  const addressList = responseData;
  if (addressList.some((item: any) => item.types[0] === 'political')) {
    const politicalObj = addressList.find((item: any) => item.types[0] === 'political');
    this.onSetLocality(politicalObj?.long_name);
  } else if (addressList.some((item: any) => item.types[0] === 'sublocality_level_1')) {
    const subLocalityObj1 = addressList.find((item: any) => item.types[0] === 'sublocality_level_1');
    this.onSetLocality(subLocalityObj1?.long_name);
  } else if (addressList.some((item: any) => item.types[0] === 'sublocality_level_2')) {
    const subLocalityObj2 = addressList.find((item: any) => item.types[0] === 'sublocality_level_2');
    this.onSetLocality(subLocalityObj2?.long_name);
  } else if (addressList.some((item: any) => item.types[0] === 'locality')) {
    const localityObj = addressList.find((item: any) => item.types[0] === 'locality');
    this.onSetLocality(localityObj?.long_name);
  } else if (addressList.some((item: any) => item.types[0] === 'administrative_area_level_2')) {
    const administrativeObj2 = addressList.find((item: any) => item.types[0] === 'administrative_area_level_2');
    this.onSetLocality(administrativeObj2?.long_name);
  } else if (addressList.some((item: any) => item.types[0] === 'administrative_area_level_1')) {
    const administrativeObj1 = addressList.find((item: any) => item.types[0] === 'administrative_area_level_1');
    this.onSetLocality(administrativeObj1?.long_name);
  }


  if (addressList.some((item: any) => item.types[0] === 'sublocality_level_1')) {
    const subLocalityObj1 = addressList.find((item: any) => item.types[0] === 'sublocality_level_1');
    this.onSetGoogleLocation(subLocalityObj1?.long_name);
  }
}
getCityStateCountry(address: any) {
  const addressList = address;
  const countryObj = addressList.find((itm: any) => itm.types[0] === 'country');
  const country = countryObj !== undefined ? countryObj?.long_name : '';
  const ctyCode = countryObj !== undefined ? countryObj?.short_name : '';
  const pinCodeObj = addressList.find((itm: any) => itm.types[0] === 'postal_code');
  const pinCode = pinCodeObj !== undefined ? pinCodeObj.long_name : '';
  const stateObj = addressList.find((itm: any) => itm.types[0] === 'administrative_area_level_1');
  const state = stateObj !== undefined ? stateObj.long_name : '';
  const cityObj = addressList.find((itm: any) => itm.types[0] === 'locality' && itm.types[1] === 'political');
  let city = '';
  if (cityObj) {
    city = cityObj?.long_name;
  } else {
    const cityObj1 = addressList.find((itm: any) => itm.types[0] === 'administrative_area_level_2' && itm.types[1] === 'political');
    city = cityObj1 !== undefined ? cityObj1?.long_name : '';
  }
  console.log(city, state, country, pinCode, ctyCode);
  this.onsetCityStateCountry(city, state, country, pinCode);
}
}