import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppData } from '../app_data.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const mapApiUrl = req.url.split('?')[0];
        const token = AppData.token;
        let headers = req.headers;
        headers = headers.append('Accept', 'application/json');
        if (mapApiUrl !== 'https://maps.googleapis.com/maps/api/geocode/json') {
            headers = headers.append('Content-Type', 'application/json');
            if (token) {
                headers = headers.append('x-access-token', token);
            }
            headers = headers.append('x-app-name', 'express-delivery');
            headers = headers.append('x-provider', 'yumzyx');
        }
        console.log('====>', headers);
        req = req.clone({ headers });
        // return next.handle(req);
        return next.handle(req)
            .pipe(map(event => {
                if (event instanceof HttpResponse) {
                    console.log(event);
                    if (event.headers.get('x-access-token')) {
                        AppData.token$.next(event.headers.get('x-access-token'));
                    }
                }
                return event;
            }));
    }
}