import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppData } from './shared/app_data.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StorageService } from './shared/storage.service';
import { ApiRequestService } from './shared/api_request.service';
import { RequestUrl } from './shared/request_url.service';
import { ToastService } from './shared/toaster.service';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { NetworkInterceptor } from './shared/interceptor/network.interceptor';
import { RouteReuseStrategy } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MapViewComponent } from './shared/components/map-view/map-view.component';
import { FormsModule } from '@angular/forms';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    MapViewComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AppData,
    StorageService,
    ApiRequestService,
    RequestUrl,
    ToastService,
    ResponseInterceptor,
    TokenInterceptor,
    {
      provide: APP_INITIALIZER,
      useFactory: (ad: AppData) => () => { return ad.ngOnInit() },
      deps: [AppData],
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
