import { AppData } from "./app_data.service";

export type URL_KEYS =
    'banner' | 'getCart' | 'validateCart' | 'getCurrentAddress' | 'address' | 'senderReceiver' | 'swapLocation' | 'static_content' | 'orderPlaced' | 'postCheckout' | 'packageType';

const UrlMapping: any = {
    banner: { url: AppData.baseUrl + '/app/express-home', showMsg: false, showLoading: false },
    getCart: { url: AppData.baseUrl + '/cart', showMsg: false, showLoading: false },
    getCurrentAddress: { url: AppData.googleMapUrl + '/maps/api/geocode/json', showMsg: false, showLoading: false },
    address: { url: AppData.baseUrl + '/account/address', showMsg: false, showLoading: false },
    senderReceiver: { url: AppData.baseUrl + '/cart/express-location', showMsg: false, showLoading: false },
    validateCart: { url: AppData.baseUrl + '/order/validate-express', showMsg: false, showLoading: false },
    swapLocation: { url: AppData.baseUrl + '/cart/express-location/swap', showMsg: false, showLoading: false },
    static_content: { url: AppData.baseUrl + '/app/content', showMsg: false, showLoading: false },
    orderPlaced: { url: AppData.baseUrl + '/order/express-order', showMsg: false, showLoading: false },
    postCheckout: { url: AppData.baseUrl + '/order/postCheckout', showMsg: false, showLoading: false },
    packageType: { url: AppData.baseUrl + '/cart/express-option', showMsg: false, showLoading: false }
};

export class RequestUrl {
    static get(key: URL_KEYS): any {
        return UrlMapping[key];
    }
}

