/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Router, Route} from '@angular/router';
import { AppData } from '../app_data.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(public router: Router) { }

  CanMatchFn() {
    if (AppData.isAuthenticated) {
      return of(true);
    }
    // this.router.navigate(['landing']);
    return of(true);
  }
}
