import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root"
})
export class AppData implements OnInit {
  static token$ = new BehaviorSubject<any>('');
  static token = '';
  static baseUrl = environment.baseUrl;
  static googleMapUrl = environment.googleMapUrl;

  static internetService$ = new BehaviorSubject<any>(true);
  static isAuthenticated$ = new BehaviorSubject<any>('');
  
  static cartIdSub$ = new BehaviorSubject<any>(null); // contain cartId
  static cartId = null;

  static orderInfo$ = new BehaviorSubject<any>(null); // contain cartId
  static orderInfo = null;

  static isAuthenticated = false;

  constructor(private storageService: StorageService) { }
  // for setting current location
  ngOnInit() { //eslint-disable-line 
    if (this.storageService.getItem('cartId') !== null) {
      AppData.cartIdSub$.next(this.storageService.getItem('cartId'));
    }
    console.log(AppData.cartIdSub$,AppData.cartId)
    AppData.cartIdSub$.subscribe((cartId) => {
      this.storageService.setItem('cartId', cartId);
      AppData.cartId = cartId;
    });

    if (this.storageService.getItem('orderInfo') !== null) {
      AppData.orderInfo$.next(this.storageService.getItem('orderInfo'));
    }
    AppData.orderInfo$.subscribe((orderInfo) => {
      this.storageService.setItem('orderInfo', orderInfo);
      AppData.orderInfo = orderInfo;
    });

    AppData.token$.subscribe((data: any) => {
     
      if (data) {
        AppData.isAuthenticated = true;
      } else {
        AppData.isAuthenticated = false;
      }
      AppData.token = data;
    })

  }
}

