/* eslint-disable no-prototype-builtins */
import { Component, OnInit } from '@angular/core';
import { InternetService } from './shared/internet_service';
import { AppData } from './shared/app_data.service';
import { filter, map } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'yumzy-express-app';
  is_online$: any;
  is_online = true;
  constructor(private internetService: InternetService, private router: Router, private navCtrl: NavController, private modalCtrl: ModalController) {
    this.is_online$ = internetService.isOnline$;
    this.is_online$.subscribe((data: any) => {
      this.is_online = data;
      if (data) {
        AppData.internetService$.next('true');
      }
    });
    router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url)
      )
      .subscribe((url: any) => {
        console.log(url);
        if (url.includes("/landing")) {
          (window as any).androidJsHandler.receivedDataFromServer('position', JSON.stringify({ code: 101, location: 'home' }));
        } else {
          (window as any).androidJsHandler.receivedDataFromServer('position', JSON.stringify({ code: 101, location: '' }));
        }
      });
  }

  ngOnInit(): void {

    window.addEventListener('message', (event) => {
      console.log(event, 'Back button pressed in Android WebView');
      if (event.data === 'navigateBack') {
        const url = new URL(window.location.href);
        this.modalCtrl.getTop().then((data: any) => {
          if (data) {
            this.modalCtrl?.dismiss();
          }
        })
        // Get the value of the 'navState' query parameter
        const navState = url.searchParams.get('navState');
        console.log(navState);
        if (navState) {
          switch (navState) {
            case '0':
              this.navCtrl.navigateRoot('/landing');
              break;
            case '1':
              this.navCtrl.navigateRoot('/location', { queryParams: { navState: 0 } });
              break;
            case '2':
              this.navCtrl.navigateRoot('/landing');
              break;
            case '3':
              this.navCtrl.navigateRoot('/landing');
              break;
            case '4':
              this.navCtrl.navigateRoot('/landing');
              break;
            default:
              this.navCtrl.navigateRoot('/landing');
          }
        }
        // Handle back button press and navigate to the desired page
        // Inject NavigationService here if needed
        // const navigationService = angular.element(document.body).injector().get('NavigationService');
        // navigationService.handleBackNavigation();
      }
    });
    if (window.hasOwnProperty('androidJsHandler')) {
      const data = (window as any).androidJsHandler.accessToken();
      if (data) {
        AppData.token$.next(data);
        AppData.isAuthenticated$.next('');
      }
    }
    // remove this before pushing;
    //   setTimeout(() => {
    //     AppData.token$.next('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJkYmI4OTVkMC0wMGU5LTExZWMtOTA1NS1kNzgxYmEwOWZjMjEiLCJhcHAiOiJzdWdnZXN0aW9ucyIsImRldmljZUlkIjoiYW5kcm9pZC1iYTUzNTkxMi1kMmM4LWNhZTgtZmI3NS1jY2Y4N2FjYTNkNDkiLCJpYXQiOjE3MjAxMDM1MzN9.ur-bpM65Mm5oerHbizyrSGhkMuheiHWsgxPwI9i4gK4');
    //     AppData.isAuthenticated$.next('');
    //     console.log(AppData.token)
    //   }, 4000);
    }

  }
