import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocationService } from 'src/app/module/location-page/service/location.service';
declare let google: any;

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit, AfterViewInit {
  @Input() from!: string;
  @Input() co_ordinates!:any;
  @ViewChild('googleMap', { static: true })
  googleMapRef!: ElementRef;
  @ViewChild('map', { static: false }) mapElement!: ElementRef;
  @ViewChild('searchInput1', { static: false }) searchInput!: ElementRef;
  searchText = '';
  private marker: any;
  private map: any;
  lat: any;
  lng: any;
  locality: any;
  googleLocation: any;
  buildingName: any;
  searchStringM = '';

  constructor(private modalCtrl: ModalController, private ngZone: NgZone, private locationService: LocationService) {
    this.locationService.init(this.setLocality.bind(this), this.setLatLng.bind(this),
      this.setFullAddress.bind(this), this.setCityStateCountry.bind(this), this.setGoogleLocation.bind(this));

  }
  ngOnInit(): void {
    if (this.from && this.from === 'collect' && !this.locationService.senderAddressDetailsInfo.saveType) {
      this.locationService.resetSenderObj();

    } else if (this.from && this.from === 'drop' && !this.locationService.receiverAddressDetailsInfo.saveType) {
      this.locality.resetReceiverObj();
    }
  }

  ngAfterViewInit(): void {
    if(!this.co_ordinates){
      this.getCurrentLocation();
    }else{
      this.lat = this.co_ordinates.lat;
      this.lng = this.co_ordinates.lng;
      this.locationService.getLocBaseOnLatLng(this.lat, this.lng);
    }
    this.initilizeMap();
  }

  dismiss() {
    this.modalCtrl.dismiss();

  }

  onClearInput() {
    this.searchStringM = '';
  }

  initAutocomplete() {
    this.searchText = '';
    const input = document.getElementById('pac-input-1') as HTMLInputElement;
    console.log(input)
    const searchBox = new google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      this.ngZone.run(() => {
        const places: any = searchBox.getPlaces();
        if (places!.length === 0) {
          return;
        } else if (places && places.length === 1) {
          this.lat = places[0].geometry.location.lat();
          this.lng = places[0].geometry.location.lng();
          this.updateMapAndMarker();
          this.locationService.getLocBaseOnLatLng(this.lat, this.lng);
        }
      });
    });
  }
  getCurrentLocation(): void {
    this.buildingName = '';
    this.locationService.getCurrentPosition()
      .then(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.updateMapAndMarker();
        this.locationService.getLocBaseOnLatLng(this.lat, this.lng);
      })
      .catch(error => {
        console.log(error);
      });
  }

  updateMapAndMarker(): void {
    if (this.map && this.marker) {
      const newLatLng = new google.maps.LatLng(this.lat, this.lng);
      this.marker.setPosition(newLatLng);
      this.map.setCenter(newLatLng);
    }
  }
  setLatLng(location: any) {
    this.lat = location.lat;
    this.lng = location.lng;
    console.log(this.lat, this.lng)
    if (this.lat && this.lng) {
      if(this.from === 'collect'){
        this.locationService.senderAddressDetailsInfo['longLat']['coordinates'] = [this.lat, this.lng];
      }else{
        this.locationService.receiverAddressDetailsInfo['longLat']['coordinates'] = [this.lat, this.lng];
      }
      
    }
  }

  setLocality(val: any) {
    this.locality = val;
  }

  setGoogleLocation(val: any) {
    this.googleLocation = val;
  }

  setCityStateCountry(city: string, state: string, country: string, pincode: string) {
    if(this.from === 'collect'){
      Object.assign(this.locationService.senderAddressDetailsInfo, { city, state, country, pincode });
    }else{
      Object.assign(this.locationService.receiverAddressDetailsInfo, { city, state, country, pincode });
    }
  }

  setFullAddress(val: any) {
    if (this.from === 'collect') {
      this.locationService.senderAddressDetailsInfo['googleLocation'] = val;
    } else {
      this.locationService.receiverAddressDetailsInfo['googleLocation'] = val;
    }
    document.getElementById("building")!.innerHTML = this.buildingName || this.locationService.senderAddressDetailsInfo['googleLocation'].split(",")[0];
    document.getElementById("fullAddr")!.innerHTML = this.from === 'collect' ? this.locationService.senderAddressDetailsInfo['googleLocation'] : this.locationService.receiverAddressDetailsInfo['googleLocation'];
  }

  initilizeMap() {
    const mapOptions = {
      center: { lat: this.lat, lng: this.lng },
      zoom: 19,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      position: mapOptions.center,
      draggable: true,
    });
    this.map.addListener('click', async (event: any) => {
      await this.marker.setPosition(event.latLng);
      this.lat = event.latLng.lat();
      this.lng = event.latLng.lng();
      this.locationService.getLocBaseOnLatLng(this.lat, this.lng);
      setTimeout(() => {
        const address = document.querySelector('.gm-style-iw');
        this.buildingName = address?.getAttribute('aria-label') || 'Unknown';
      })
    });
    this.marker.addListener('dragend', () => {
      const newPosition = this.marker.getPosition().toJSON();
      this.lat = newPosition.lat;
      this.lng = newPosition.lng;
      this.buildingName = '';
      this.locationService.getLocBaseOnLatLng(this.lat, this.lng);
    });
  }

  saveMapLoc() {
    const type = this.from;
    const saveType = true;
    if (this.from === 'collect') {
      this.modalCtrl.dismiss({ addressDetailsInfo: { ...this.locationService.senderAddressDetailsInfo, type, saveType } });
    } else {
      this.modalCtrl.dismiss({ addressDetailsInfo: { ...this.locationService.receiverAddressDetailsInfo, type, saveType } });
    }
  }
}
