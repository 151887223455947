import { Injectable } from '@angular/core';
import { Observable, fromEvent, map, merge, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternetService {
  isOnline$: Observable<boolean>;

  constructor() {
    this.isOnline$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );
  }
}
