export enum ErrorCode {
  TOKEN_INVALID = 1009,
  TOKEN_EXPIRED = 1009
}
export const ADDRESS_BY_CITY = {
  types: [],
  componentRestrictions: { country: 'IN' },
  fields: ['address_components', 'geometry', 'place_id', 'formatted_address'],
};

export const PAYTMINFO = {
  PROJECT_NAME: 'YUMZY EXPRESS',
  LINKS: {
    CHECKOUT_JS_URL: '/merchantpgpui/checkoutjs/merchants/'
  },
  ERRORS: {
    INIT: 'An error during initialization!',
    INVOKE: 'An error during invoking!',
    MERCHANT_ID_NOT_FOUND: 'Please provide merchant id!',
    CHECKOUT_NOT_AVAILABLE: 'Checkout JS library not found. Please make sure you have included checkout js!',
    INVALID_CHECKOUT_JS_INSTANCE: 'Invalid instance provided!'
  },
  IDS: {
    CHECKOUT_ELEMENT: 'checkout-wrapper-'
  }
};

// Prefix error with project name
Object.keys(PAYTMINFO.ERRORS).forEach(errorCode => {
  (PAYTMINFO.ERRORS as any)[errorCode] = `${PAYTMINFO.PROJECT_NAME}: ${(PAYTMINFO.ERRORS as any)[errorCode]}`;
});

  export const LOTTIE_CONFIG = {
    CONFIRMATION_CHECK: {
      path: 'assets/lotties-files/success.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false,
      showConfig: false
    },
    LOADING_SPINNER: {
      path: 'assets/lotties-files/spinner.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true,
      showConfig: false
    },
    FAILURE_CHECK: {
      path: 'assets/lotties-files/spinner-fail.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false,
      showConfig: false
    },
  };

export const CONFIG = {
  flow: "DEFAULT",
  merchant: {
    mid: "",
    name: "",
    logo: "",
    redirect: true,
  },
  style: {},
  jsFile: "",
  data: {
    orderId: "",
    amount: "",
    token: "",
    tokenType: "TXN_TOKEN",
    userDetail: {
      mobileNumber: "",
      name: ""
    }
  },
  mapClientMessage: {},
  labels: {},
  payMode: {
    labels: {},
    filter: {
      exclude: []
    },
    order: [
    ]
  }
};