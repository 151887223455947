import { Injectable } from '@angular/core';

export type ToasterType = 'success' | 'error' | 'warning' | 'info';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  async presentToast(message: any) {
    const x = document.getElementById('snackbar');
    console.log(x);
    if (x) {
      x.className = '';
      setTimeout(() => {
        x.className = 'show';
        x.innerHTML = message;

        setTimeout(() => {
          x.className = x.className.replace('show', '');
        }, 3000)
      }, 200)
    }
    // this.toasterService[type](message, '', this.toasterConfig);
  }
}
